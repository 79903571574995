.custom-fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content-wrapper {
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Header modal */
.modal-header {
  position: absolute;
  top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Tombol close untuk desktop */
.desktop-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
  position: absolute;
  right: 20px; /* Tetap di pojok kanan atas */
  top: 10px;
  display: inline;
}

/* Mobile header (back button + title) */
.mobile-header {
  display: none; /* Default hidden */
  align-items: center;
  gap: 10px;
}

/* Tombol back untuk mobile */
.mobile-back {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
}

/* Title untuk mobile */
.modal-title {
  font-size: 16px;
  font-weight: bold;
  max-width: 200px; /* Batasi lebar */
  white-space: nowrap; /* Jangan turun ke bawah */
  overflow: hidden;
  text-overflow: ellipsis; /* Tambahkan "..." jika terlalu panjang */
}

/* Saat di mobile */
@media (max-width: 768px) {
  .modal-header {
    justify-content: flex-start;
  }

  .mobile-header {
    display: flex; /* Tampilkan title dan back button */
  }

  .desktop-close {
    display: none; /* Sembunyikan tombol X */
  }
}

/* Saat di desktop */
@media (min-width: 769px) {
  .modal-title {
    display: none; /* Sembunyikan title di desktop */
  }
}
