.shadow-web {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

@media (max-width: 768px) {
  .shadow-web {
    box-shadow: none;
    padding: 0;
  }
}

.img-web-only {
  display: block;
}

@media (max-width: 768px) {
  .img-web-only {
    display: none;
  }
}

.input-mobile-borderless {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: red;
}

@media (max-width: 768px) {
  .input-mobile-borderless {
    border: 1px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    font-size: 2rem; /* fs-2 */
  }
}

.mobile-only {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mobile-only {
    display: block;
  }
}
