.register-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.card-wrapper {
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-radius: 10px;
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .card-wrapper {
    padding: 24px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
  }
}

@media (max-width: 768px) {
  .register-container {
    justify-content: flex-start;
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .card-wrapper {
    padding-top: 10px;
    box-shadow: none;
    border: none;
  }

  .btn-lanjut {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 1000;
    border-top: 1px solid #eaeaea;
    padding: 20px;
  }
}

.mobile-header {
  display: none;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  z-index: 1000;
}

.chevron-icon {
  margin-right: 10px;
  cursor: pointer;
}

.header-title {
  margin: 0;
}

@media (max-width: 768px) {
  .mobile-header {
    display: flex;
    padding: 15px;
  }

  .register-container {
    padding-top: 60px;
  }

  .btn-lanjut {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 1000;
    border-top: 1px solid #eaeaea;
    padding: 20px;
  }
}

.logo {
  margin-bottom: 24px;
  max-width: 177px;
  height: auto;
}

@media (max-width: 768px) {
  .logo {
    display: none;
  }
}
