.centered-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fcfcfd;
}

@media (max-width: 768px) {
  .card-mobile {
    padding: 0 !important;
    box-shadow: none !important;
  }
  .centered-container {
    background-color: #ffffff !important;
  }
}

.modal-dialog-bottom {
  position: fixed !important;
  bottom: 0;
  margin: 0;
  width: 100%;
  max-width: none;
  border-radius: 12px 12px 0 0;
}

.modal-content {
  border-radius: 12px 12px 0 0;
  box-shadow: none;
}

.custom-button {
  transition:
    background-color 0.3s,
    color 0.3s;
}

.custom-button:hover {
  background-color: red;
  color: white;
}

.custom-button:hover .custom-text {
  color: white;
}

.suggestion-container {
  border: 1px solid #d0d5dd;
  margin-top: 5px;
  border-radius: 5px;
  background: white;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
  margin: 0;
}

.suggestion-item:not(:last-child) {
  border-bottom: 1px solid #d0d5dd;
}
